function SetHref(ref) {
        if (typeof ref != "undefined") {
                document.getElementById("path").value = ref;
        }
                document.forms["Filter"].submit();
}

$("div.coord")
    .mouseover(function() {
            $(this).find("span").text( "mouse over x " + i );
    })
    .mouseout(function() {
            $(this).find("span").text( "mouse out " );
    });

$('.over.text-muted').hide();


    $('#sku').dataTable( {
        "paging":   false,
        "ordering": true,
        "info":     false,
    } );
    $('#map').dataTable( {
        "paging":   false,
        "ordering": true,
        "info":     true,
        responsive: true
    } );

$(document).tooltip({
    items:'.article',
    tooltipClass: 'preview-tip',
    position: { my: "left+15 top", at: "right center" },
    content: function(callback) {
        var elem = $(this);
        $.ajax({
            type: "GET",
            url: "info.php",
            data: "sku="+elem.attr('data-sku'),
            dataType: "json",
            success: function(data) {
                if(data.success){
                    //elem.hide();
                    elem.attr("title", data.message);
                }
            }
        });
    },
});

$("#btn-filter").on('click touch', function() {
    $('.over.text-muted').show();
});
$("#btn-unfilter").on('click touch', function() {
    $('.over.text-muted').hide();
});

jQuery(document).ready(function($){

    $('img[usemap]').rwdImageMaps();
    $('img[usemap]').maphilight({ fade: false, stroke: false, fillColor: 'ffb600', fillOpacity: 0.5 });

    $('#sku').dataTable( {
        destroy: true,
        "paging":   false,
        "ordering": true,
        "info":     false,
    } );
});

jQuery(window).bind('resize orientationchange', function(e) {
    var windowWidth = $(window).width();
    jQuery(window).resize(function(){
        jQuery('img[usemap]').maphilight({ fade: false, stroke: false, fillColor: 'ffb600', fillOpacity: 0.5 });
    })
});


$("tr.over")
    .hover(
        function() {
            //mouse over
            var name = $(this).attr("name");
            $("area[name='AR" + name + "']").mouseover();
            $(this).addClass('success');
        }, function() {
            //mouse out
            var name = $(this).attr("name");
            $("area[name='AR" + name + "']").mouseout();
            $(this).removeClass('success');
        }
    );

$("tr.over").on('click touch', function(e) {
    //e.preventDefault();
    var name = $(this).attr("name");
    var data = $("area[name='AR" + name + "']").data("maphilight") || {};
    data.fillColor = 'dd4814';
    data.alwaysOn = !data.alwaysOn;
    $("area[name='AR" + name + "']").data('maphilight', data).trigger("alwaysOn.maphilight");

    if ( $(this).hasClass("look") ) {
        $(this).removeClass("look");
    } else {
        $(this).addClass("look");
    }
});

$("area").hover(
    function() {
        //mouse over
        var name = $(this).attr("name");
        name = name.substring(2, name.length);
        $("tr[name='" + name + "']").addClass('success');
    }, function() {
        //mouse out
        var name = $(this).attr("name");
        name = name.substring(2, name.length);
        $("tr[name='" + name + "']").removeClass('success');
    }
);

$("area").hover(
    function() {
        //mouse over
        var name = $(this).attr("name");
        name = name.substring(2, name.length);
        $("tr[name='" + name + "']").addClass('success');
    }, function() {
        //mouse out
        var name = $(this).attr("name");
        name = name.substring(2, name.length);
        $("tr[name='" + name + "']").removeClass('success');
    }
);

$(".catalog-item").hover(
    function() {
        //mouse over
        $(this).css('background-color', '#F5F5F5')
    }, function() {
        //mouse out
        $(this).css('background-color', 'transparent')
    }
);


$("area").on('click touch', function(e) {
    e.preventDefault();

    var name = $(this).attr("name");
    name = name.substring(2, name.length);

    var data = $(this).data("maphilight") || {};
    data.fillColor = 'ffe523';
    data.alwaysOn = !data.alwaysOn;

    $("area[name='AR" + name + "']").data('maphilight', data).trigger("alwaysOn.maphilight");

    if ( $("tr[name='" + name + "']").hasClass("look") ) {
        $("tr[name='" + name + "']").removeClass("look");
    } else {
        $("tr[name='" + name + "']").addClass("look");
    }
});

$(function() {
    $(this).bind("contextmenu", function(e) {
        e.preventDefault();
    });
});


$(document).ready(function(){

    $(".filter-button").click(function(){
        var value = $(this).attr('data-filter');
        if(value == "all")
        {
            $('.filter').show('1000');
        }
        else
        {
            $(".filter").not('.'+value).hide('3000');
            $('.filter').filter('.'+value).show('3000');
        }
    });

});

window.addEventListener("keydown", function (event) {
  if (event.defaultPrevented) {
    return; // Do nothing if the event was already processed
  }

    // Ctrl+C or Cmd+C pressed?
      if ((event.ctrlKey || event.metaKey) && event.keyCode == 67) {
        event.preventDefault();
        navigator.clipboard.writeText("defender.org.ua");
      }

    // Ctrl+X or Cmd+X pressed?
      if ((event.ctrlKey || event.metaKey) && event.keyCode == 88) {
        event.preventDefault();
        navigator.clipboard.writeText("defender.org.ua");
      }


  switch (event.key) {
    case "Left": // IE/Edge specific value
    case "ArrowLeft":
      // Do something for "left arrow" key press.
      var luri =  $('ul.pager li.previous a').attr('href');
      if (Boolean(luri)) {
        window.location = luri;
      }
      break;
    case "Right": // IE/Edge specific value
    case "ArrowRight":
      // Do something for "right arrow" key press.
      var ruri =  $('ul.pager li.next a').attr('href');
      if (Boolean(ruri)) {
        window.location = ruri;
      }
      break;
    case "Esc": // IE/Edge specific value
    case "Escape":
      // Do something for "esc" key press.
      window.history.back();
      break;
    default:
      return; // Quit when this doesn't handle the key event.
  }

  // Cancel the default action to avoid it being handled twice
  event.preventDefault();
}, true);